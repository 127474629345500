import { computed, defineComponent, unref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Toast } from "vant";
import { loginValidate } from "@/hooks/component/loginValidate";
import { getWechatTicketInfo } from "@/apis";
import { globalStorage } from "@/utils/storage/base";
export default defineComponent({
    name: "AuthLogin",
    setup() {
        const { currentRoute, replace } = useRouter();
        // form表单
        const { handleLoginCorps } = loginValidate();
        // 来自哪
        const from = computed(() => unref(currentRoute).query.from ?? "");
        // code码
        const code = computed(() => unref(currentRoute).query.code ?? "");
        // 是否绑定
        const binded = computed(() => unref(currentRoute).query.binded ?? "");
        const store = useStore();
        // 跳转微信授权页
        function jumpWechat(appId) {
            const url = encodeURIComponent(window.location.href);
            // location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
            location.href = `https://infrastructure.h3yun.com/wechatofficial/redirectOauth?re=https://infrastructure.h3yun.com/wechatofficial/oauth&scope=snsapi_userinfo`;
        }
        // 获取微信公众号相关信息
        async function getWechatInfo() {
            const { Successful, ReturnData, ErrorMessage } = await getWechatTicketInfo();
            if (Successful) {
                const data = { ...ReturnData };
                jumpWechat(data.Sign);
            }
            else {
                Toast(ErrorMessage);
            }
        }
        // 跳转登录页
        function jumpLogin(value = '') {
            // 后续需要操作code码 防止注册成功后返回失效
            globalStorage.setToLocal("login_wechat_code", value);
            replace({
                name: "AuthLogin",
                query: {
                    from: "wechat",
                    wechatcode: value
                }
            });
        }
        // 微信登录
        function handleLoginWechat() {
            store
                .dispatch("loginWithWechatAction", {
                code: code.value
            })
                .then(res => {
                handleLoginCorps(res);
            })
                .catch(errmsg => {
                if (errmsg !== "无效的授权码")
                    Toast(errmsg);
                setTimeout(() => {
                    jumpLogin();
                }, 500);
            });
        }
        onMounted(() => {
            // code存在 如果已绑定 则直接登录或者跳转选择企业 如果未绑定 跳转登录页
            if (code.value && binded.value === "1") {
                handleLoginWechat();
            }
            else if (from.value === "wechat") {
                getWechatInfo();
            }
            else {
                Toast("您未绑定氚云账号\n请登录进行绑定");
                setTimeout(() => {
                    jumpLogin(code.value);
                }, 500);
            }
        });
    }
});
